<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <a-icon type="team" />{{ l("批量通知签署") }}
      </div>
    </div>
    <!-- 搜索框 -->
    <!-- <a-input-search
      name="filterText"
      @search="getData"
      :placeholder="l('SearchWithThreeDot')"
      enterButton
      v-model="filterText"
    /> -->
    <!-- 数据部分 -->
    <div class="my-md">
      <a-alert :type="'info'" :showIcon="true">
        <template slot="message">
          <span
            v-html="l('GridSelectedXItemsTips', selectedRowKeys.length)"
          ></span>
          <a @click="restCheckStatus()" class="ml-md">{{ l("ClearEmpty") }}</a>
          <a-divider type="vertical"></a-divider>
          <a @click="clearFilterAndRefresh()">{{ l("Refresh") }}</a>
        </template>
      </a-alert>
    </div>
    <!-- table -->
    <a-table
      @change="handleChange"
      :pagination="false"
      :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :columns="columns"
      :rowKey="(data) => data.value"
      :dataSource="data"
    >
    </a-table>
    <!-- 分页 -->
    <!-- <a-pagination
      class="pagination"
      size="middle"
      :total="totalItems"
      showSizeChanger
      showQuickJumper
      :showTotal="showTotalFun"
      @change="onChange"
      @showSizeChange="showSizeChange"
    /> -->
    <!-- 按钮 -->
    <div class="modal-footer">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ l("Cancel") }}
      </a-button>
      <a-button :loading="saving" :type="'primary'" @click="_handleSubmit()">
        <a-icon type="save" />
        {{ l("Save") }}
      </a-button>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { OrganizationUnitServiceProxy } from "@/shared/service-proxies";
import moment from "moment";
import Bus from "@/shared/bus/bus";
import { fileDownloadService } from "@/shared/utils";
export default {
  name: "batchnotification_list",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      // 搜索
      filterText: "",
      // 选中item
      selectedRowKeys: [],
      selectedRows:[],
      // 分页
      request: { maxResultCount: 30, skipCount: 0 },
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      _organizationUnitServiceProxy: null,
      commonService: null,
      // 用户表格
      columns: [
        {
          title: this.l("协议模板名称"),
          dataIndex: "name",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("协议模板签署有效期"),
          dataIndex: "signEffective",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "signEffective" },
        },
        {
          title: this.l("是否默认"),
          dataIndex: "isDefaultStr",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "isDefaultStr" },
        },
        {
          title: this.l("是否指定签署"),
          dataIndex: "isOrderSignStr",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "isOrderSignStr" },
        },
        {
          title: this.l("是否作废"),
          dataIndex: "isInvalidStr",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "isInvalidStr" },
        },
      ],
      // 用户数据
      data: [],
    };
  },
  created() {
    console.log(this);
    this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(
      this.$apiUrl,
      this.$api
    );
    // this.getData();
    this._getData();
  },
  methods: {
    /**
     * 获取数据
     */
    _getData() {
      this._getListByProjectId();
    },
    async _getListByProjectId() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectAgreement/GetListByProjectId",
          params: {
            projectId: this.projectId,
            isInvalid:false,
          },
        });
        this.data = res.map((item) => {
          return {
            ...item,
            isInvalidStr: item.isInvalid ? "是" : "否",
            invalidedTime: item.invalidedTime
              ? moment(item.invalidedTime).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            invalidReason: item.invalidReason ? item.invalidReason : "-",
            isDefaultStr: item.isDefault ? "是" : "否",
            isOrderSignStr: item.isOrderSign ? "是" : "否",
          };
        });
        this.totalItems;
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    getData() {
      this.spinning = true;
      this._organizationUnitServiceProxy
        .findUsers(
          Object.assign(
            {
              organizationUnitId: this.organizationUnitId,
              filterText: this.filterText,
            },
            this.request
          )
        )
        .finally(() => {
          this.spinning = false;
        })
        .then((result) => {
          this.data = result.items.map((o) => {
            return {
              ...o,
              id: parseInt(o.value),
            };
          });
          this.totalItems = result.totalCount;
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
          this.totalPages = Math.ceil(
            result.totalCount / this.request.maxResultCount
          );
          console.log(result);
        });
    },
    /**
     * 提交表单
     */
    handleSubmit() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      this.saving = true;
      this.spinning = true;
      let input = {
        organizationUnitId: this.organizationUnitId,
        userIds: this.selectedRowKeys,
      };
      this._organizationUnitServiceProxy
        .addUsers(input)
        .finally(() => {
          this.saving = false;
          this.spinning = false;
        })
        .then(() => {
          this.notify.success(this.l("SavedSuccessfully"));
          this.success(input.userIds);
        });
    },
    _handleSubmit() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      let projectAgreementIds = this.selectedRows.map((item) => {
        return item.id;
      });
      let _that = this;
      _that.message.confirm(
        `是否对${this.selectUserIds.length}个人进行批量通知签署`,
        "通知签署",
        (res) => {
          if (res) {
            _that.handleData(projectAgreementIds);
          }
        }
      );
    },
    async handleData(projectAgreementIds) {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/ProjectUser/BatchNoticeSign",
          params: {
            personnelIds: this.selectUserIds,
            projectAgreementIds: projectAgreementIds,
          },
        });
        console.log(res)
                if(res.file)
        fileDownloadService.downloadTempFile(res.file);
        this.$notification["success"]({
          message: this.l(res.message),
        });
        this.spinning = false;
        // this.success();
      } catch (error) {
        this.spinning = false;
      }
    },
    /**
     * 清空选择
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    /**
     * 清除条件并刷新
     */
    clearFilterAndRefresh() {
      this.request = { maxResultCount: 30, skipCount: 0 };
      this.filterText = "";
      this._getData();
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * 选中table
     */
    onSelectChange(selectedRowKeys,selectedRows) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      console.log("selectedRows changed: ", selectedRows)
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    handleChange(pagination, filters, sorter) {
      console.log("Various parameters", pagination, filters, sorter);
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    /**
     * 分页
     */
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  .anticon {
    margin-right: 10px;
  }
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
