<template>
  <a-spin :spinning="spinning">
    <!-- <a-card
      :bordered="false"
      style="height: 700px; max-height: 700px; overflow-y: auto"
    > -->
      <ele-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :columns="columns"
        :current-page="pageNumber"
        :select-type="queryParams.selectType"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row :gutter="8" type="flex" style="margin-bottom: 10px">
          <a-col class="gutter-row" :span="4">
            <a-input v-model="SearchDto.Name" :placeholder="l('NameSurname')" />
          </a-col>
          <a-col class="gutter-row" :span="4">
            <a-input
              v-model="SearchDto.Phone"
              :placeholder="l('ProjectUserPhone')"
            />
          </a-col>
          <a-col class="gutter-row" :span="4">
            <a-input
              v-model="SearchDto.IdCard"
              :placeholder="l('ProjectUserIdCard')"
            />
          </a-col>
          <a-col class="gutter-row" :span="6">
            <a-button type="primary" @click="search">
              {{ l("Search") }}
            </a-button>
            <a-button @click="clearFilterAndRefresh"> {{ l("Reset") }}</a-button>
          </a-col>
        </a-row>
      </ele-table>

      <!-- <a-table :data-source="tableData" :pagination="pageData" @change="tableChange"
                     :row-selection="{ selectedRowKeys: selectedRowKeys,onChange: selectChange }" style="margin-top: 20px;">
                <a-table-column-group>
                    <a-table-column :key="1" align="center" :title="l('NameSurname')" data-index="realName" width="100px" />
                    <a-table-column :key="2" align="center" :title="l('ProjectUserPhone')" data-index="phoneNumber" />
                    <a-table-column :key="3" align="center" :title="l('ProjectUserIdCard')" data-index="idCard" />
                    <a-table-column :key="4" align="center" :title="l('BankAccountNo')" data-index="bankCardNo" />
                    <a-table-column :key="5" align="center" :title="l('BankName')" data-index="bankName" />
                    <a-table-column :key="6" align="center" :title="l('BankArea')" data-index="bankBranchName" />
                    <a-table-column :key="7" align="center" :title="l('IsRealName')" data-index="isRealNamed">
                        <template slot-scope="text, scope">
                            <a-tag :color="text ? 'cyan' : 'red'">{{text ? l('RealNamed') : l('NotRealNamed')}}</a-tag>
                        </template>
                    </a-table-column>
                </a-table-column-group>
            </a-table> -->
    <!-- </a-card> -->
    <a-row :gutter="8">
      <a-col :span="24" align="right">
        <a-button type="primary" @click="SureSelected">{{ l("Sure") }}</a-button
        >&nbsp;
        <a-button @click="CloseModel">{{ l("Close") }}</a-button>
      </a-col>
    </a-row>
  </a-spin>
</template>
<script>
import EleTable from "@/components/ele-table";
import { ModalComponentBase } from "@/shared/component-base";
import axios from "axios";
import moment from "moment";

export default {
  mixins: [ModalComponentBase],
  components: { EleTable },
  name: "projectmodule",
  data: function () {
    return {
      spinning: false,
      SearchDto: {
        Name: "",
        Phone: "",
        IdCard: "",
        SkipCount: 0,
        MaxCount: 8,
      },
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      pageData: { current: 1, pageSize: 8, total: 0 },
      // ---------------------------------------------
      // 选择多少项
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      // 表格
      // columns: [],
      columns: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      personnelTagOpts: [
        {label: '自然人和非自然人', value: 0},
        {label: '自然人', value: 1},
        {label: '非自然人', value: 2},

      ]
    };
  },
  methods: {
    initColumns() {
      let vm = this;
      this.columns = [
        {
          title: this.l("NameSurname"),
          dataIndex: "realName",
          sorter: false,
          align: "center",
          width: 100,
          fixed: "left",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("ProjectUserPhone"),
          dataIndex: "phoneNumber",
          sorter: false,
          align: "center",
          width: 180,
          scopedSlots: { customRender: "phoneNumber" },
        },
        {
          title: this.l("ProjectUserIdCard"),
          dataIndex: "idCard",
          sorter: false,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: this.l("BankAccountNo"),
          dataIndex: "bankCardNo",
          sorter: false,
          align: "center",
          width: 220,
          scopedSlots: { customRender: "bankCardNo" },
        },
        {
          title: this.l("BankName"),
          dataIndex: "bankName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "bankName" },
        },
        {
          title: this.l("BankArea"),
          dataIndex: "bankBranchName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "bankBranchName" },
        },
        {
          title: this.l("IsRealName"),
          dataIndex: "isRealNamed",
          sorter: false,
          align: "center",
          fixed: "right",
          type: 'tag',
          customRender: function (text, record, index) {
            var child = vm.$createElement("a-tag", {
              domProps: {
                innerHTML: text ? vm.l("RealNamed") : vm.l("NotRealNamed"),
              },
              on: {
                click: function () {},
              },
              style: {
                color: text ? "white" : "red",
                backgroundColor: text ? "green" : "white",
              },
            });
            var obj = {
              children: child,
              attrs: {},
            };
            return obj;
          },
          scopedSlots: { customRender: "isRealNamed" },
        },
        // {
        //     title: this.l("Actions"),
        //     dataIndex: "actions",
        //     fixed: 'right',
        //     sorter: false,
        //     align: "center",
        //     width: '100',
        //     scopedSlots: { customRender: "actions" }
        // },
      ];
    },
    search() {
      this.pageNumber = 1
      this.request.skipCount = 0
      this.getData()
    },
    getData() {
      this.spinning = true;
      let url = this.$apiUrl + "/api/services/app/Personnel/GetPaged";
      let options = {
        params: {
          phoneNumber: encodeURIComponent(this.SearchDto.Phone),
          realName: this.SearchDto.Name,
          idCard: encodeURIComponent(this.SearchDto.IdCard),
          personnelTag: Number(this.queryParams.personnelTag),
          Status:Number(this.queryParams.status),
          maxResultCount: this.request.maxResultCount,
          skipCount: this.request.skipCount,
        },
        method: "GET",
        url: url,
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      };
      this.$api
        .request(options)
        .then((response) => {
          let res = response.data;
          this.tableData = res.items.map(v=> {
            return {
              ...v,
              isRealNamed: v.isRealNamed ? this.l("RealNamed") : this.l("NotRealNamed"),
              isRealNamedColor: v.isRealNamed ? "green" : "#ccc",
            }
          });
          //   this.pageData.total = res.totalItems;
          this.totalItems = res.totalCount;
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
          this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
          );
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      console.info(pagination);
      const pager = { ...this.pageData };
      pager.current = pagination.current;
      this.SearchDto.SkipCount = (pager.current - 1) * this.SearchDto.MaxCount;
      this.pageData = pager;
      this.getData();
    },
    selectChange(keys) {
      this.selectedRows.splice(0, this.selectedRows.length);
      this.selectedRowKeys = keys;
      for (let i = 0; i < keys.length; i++) {
        var index = keys[i];
        this.selectedRows.push(this.tableData[index]);
      }
    },
    SureSelected() {
      if (!this.selectedRows || this.selectedRows.length <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return false;
      }
      this.success({ success: true, data: this.selectedRows[0] });
    },
    CloseModel() {
      this.success({ success: false });
    },
    refreshGoFirstPage() {
      this.SearchDto.Name = "";
      this.SearchDto.Phone = "";
      this.SearchDto.IdCard = "";
      this.SearchDto.SkipCount = 0;
      this.pageData.current = 1;
      this.getData();
    },
    // ---------------------------------------------
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.SearchDto.Name = "";
      this.SearchDto.Phone = "";
      this.SearchDto.IdCard = "";
      this.SearchDto.personnelTag = undefined
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
  },
  created() {
    this.fullData();
    this.initColumns();
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}

.tbl-edit {
  display: block;
  margin-top: 3px;
}
</style>
