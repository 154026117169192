<template>
  <a-spin :spinning="spinning">
    <!-- <page-header :title="l('ProjectUserManager')"></page-header> -->
    <!-- <a-card :bordered="false"> -->
    <!-- table -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :scroll="{ x: 2000 }"
      :columns="columns"
      :is-full="true"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="false"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="4" type="flex" align="middle" style="margin-bottom:8px;margin-top:-5px;">
        <a-col class="gutter-row" :span="24">
          <a-tag color="pink">未申请({{CommericalInfo.notApply}})</a-tag>
          <a-tag color="blue">已提交申请({{CommericalInfo.sendApply}})</a-tag>
          <a-tag color="purple">待市监局审批({{CommericalInfo.applying}})</a-tag>
          <a-tag color="green">已下照({{CommericalInfo.success}})</a-tag>
          <a-tag color="red">认证失败({{CommericalInfo.failed}})</a-tag>
          <a-tag color="orange">注销({{CommericalInfo.terminating + CommericalInfo.terminated}})</a-tag>
          <!--<a-tag color="#ccc">已解约({{CommericalInfo.terminated}})</a-tag>-->
        </a-col>
      </a-row>
      <a-row :gutter="4" type="flex" align="middle">
        <!-- <a-col class="gutter-row" :span="2">
          <a-input
            v-model.trim="SearchDto.ProjectCode"
            :placeholder="l('ProjectHeaderCode')"
          />
        </a-col> -->
        <a-col class="gutter-row" :span="4">
          <!-- <a-input
              v-model.trim="SearchDto.ProjectName"
              :placeholder="l('ProjectHeaderName')"
            /> -->
          <a-input
            v-model="SearchDto.ProjectName"
            :placeholder="l('ProjectHeaderName')"
            @click="selectProjectModal"
          >
          </a-input>
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-input
            v-model.trim="SearchDto.FilterText"
            placeholder="姓名/手机号/身份证号"
          />
        </a-col>
        <!-- <a-col class="gutter-row" :span="2">
          <a-input
            v-model.trim="SearchDto.RealName"
            :placeholder="l('NameSurname')"
          />
        </a-col> -->
        <!-- <a-col class="gutter-row" :span="2">
          <a-input
            v-model.trim="SearchDto.Phone"
            :placeholder="l('ProjectUserPhone')"
          />
        </a-col> -->
        <!--日期范围 -->
        <a-col class="gutter-row" :span="4">
          <a-range-picker
            :placeholder="[l('加入日期'), l('加入日期')]"
            v-model="startToEndDate"
          />
        </a-col>
        <!-- <a-col class="gutter-row" :span="2" style="text-align: right"
            >审核核状态:</a-col
          > -->
        <a-col class="gutter-row" :span="4">
          <a-select
            v-model="CustomerId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            :placeholder="l('PleaseSelectCustomer')"
            allowClear
          >
            <a-select-option
              v-for="item in Customers"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="5">
          <a-button-group>
            <a-button
              type="primary"
              @click="search"
              v-if="isGranted('Pages.LGModul.ProjectPersonel.Query')"
            >
              {{ l("Search") }}
            </a-button>
            <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
            <a-button @click="showSearchDrawer">高级搜索</a-button>
          </a-button-group>
          <!-- <a-dropdown>
              <a-menu slot="overlay">
                <a-menu-item key="1" v-if="isGranted('Pages.LGModul.ProjectPersonel.Create')" @click="createOrEdit()"><a-icon type="plus" />{{ l("Create") }}</a-menu-item>
                <a-menu-item key="2" v-if="
                isGranted('Pages.LGModul.ProjectPersonel.BatchApply') && selectedRowKeys.length > 0" @click="_auditRange()"><a-icon type="diff" />{{ l("批量审核") }}</a-menu-item>
              </a-menu>
              <a-button style="margin-left: 8px"> 更多 <a-icon type="down" /> </a-button>
            </a-dropdown> -->
        </a-col>
        <a-col :span="4" align="right">
          <a-button-group>
            <a-button
              type="primary"
              @click="createOrEdit()"
              v-if="isGranted('Pages.LGModul.ProjectPersonel.Create')"
            >
              <!-- <a-icon type="plus" /> -->
              {{ l("Create") }}
            </a-button>
            <a-dropdown>
              <a-menu slot="overlay">
                <!-- v-if="isGranted('Pages.LGModul.ProjectPersonel.Import') && selectedRowKeys.length > 0" -->
                <a-menu-item key="1">
                  <a-button
                    @click="importData()"
                    :disabled="
                      !isGranted('Pages.LGModul.ProjectPersonel.Import')
                    "
                  >
                    {{ l("导入") }}
                  </a-button>
                </a-menu-item>
                <a-menu-item key="2">
                  <a-button
                    @click="_getPageListExcelFile()"
                    :disabled="
                      !isGranted('Pages.LGModul.ProjectPersonel.Export')
                    "
                  >
                    {{ l("导出") }}
                  </a-button>
                </a-menu-item>
                <!-- v-if="
                        isGranted('Pages.LGModul.ProjectPersonel.BatchApply') &&
                        selectedRowKeys.length > 0
                      " -->
                <a-menu-item key="3">
                  <a-button
                    @click="_auditRange()"
                    :disabled="
                      !isGranted('Pages.LGModul.ProjectPersonel.BatchApply') ||
                      !selectedRowKeys.length
                    "
                  >
                    <!-- <a-icon type="diff" /> -->
                    {{ l("批量审核") }}
                  </a-button>
                </a-menu-item>
                <a-menu-item key="4">
                  <a-button
                    @click="batchNotification()"
                    :disabled="
                      !isGranted(
                        'Pages.LGModule.ProjectPersonel.BatchNotify'
                      ) || !selectedRowKeys.length
                    "
                  >
                    {{ l("批量通知签署") }}
                  </a-button>
                </a-menu-item>
                <a-menu-item key="5">
                  <a-button
                    @click="exportCommericalExcelFile()"
                    
                  >
                    {{ l("导出新经济主体信息") }}
                  </a-button>
                </a-menu-item>
              </a-menu>
              <a-button> 更多 <a-icon type="down" /> </a-button>
            </a-dropdown>
          </a-button-group>
        </a-col>
      </a-row>
    </ele-table>
    <a-drawer
      title="搜索"
      :width="750"
      :visible="searchDrawerVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onSearchDrawerClose"
    >
      <a-form :form="SearchDto" layout="vertical" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="项目名称">
              <a-input
                v-model="SearchDto.ProjectName"
                :placeholder="l('ProjectHeaderName')"
                @click="selectProjectModal"
              >
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="关键字">
              <a-input
                v-model.trim="SearchDto.FilterText"
                placeholder="姓名/手机号/身份证号"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="加入日期">
              <a-range-picker
                :placeholder="[l('加入日期'), l('加入日期')]"
                v-model="startToEndDate"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="客户">
              <a-select
                v-model="CustomerId"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                :placeholder="l('PleaseSelectCustomer')"
                allowClear
              >
                <a-select-option
                  v-for="item in Customers"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="合作状态">
              <a-select
                style="width: 100%"
                @change="selectCooperateStatusChange"
                placeholder="合作状态"
                v-model="SearchDto.CooperateStatus"
                allowClear
              >
                <a-select-option
                  :value="it.value"
                  v-for="(it, i) in cooperateStatusList"
                  :key="i"
                >
                  {{ it.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="审核状态">
              <a-select
                style="width: 100%"
                @change="selectChange"
                placeholder="审核状态"
                v-model="SearchDto.AuditStatus"
                allowClear
              >
                <a-select-option
                  :value="it.value"
                  v-for="(it, i) in auditStatusList"
                  :key="i"
                >
                  {{ it.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="实名状态">
              <a-select
                style="width: 100%"
                @change="selectRealNamedChange"
                placeholder="实名状态"
                v-model="SearchDto.IsRealNamed"
                allowClear
              >
                <a-select-option
                  :value="it.value"
                  v-for="(it, i) in realNamedStatusList"
                  :key="i"
                >
                  {{ it.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="签署状态">
              <a-select
                style="width: 100%"
                placeholder="签署状态"
                v-model="SearchDto.isSign"
                allowClear
              >
                <!-- <a-select-option value="">请选择</a-select-option> -->
                <a-select-option value="3">未签署</a-select-option>
                <a-select-option value="2">部分签署</a-select-option>
                <a-select-option value="1">已签署</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
                <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="签约公司">
                         <a-select
                v-model="SearchDto.CompanyId"
                show-search
                :filter-option="filterOption"
                style="width: 100%"
                :placeholder="l('请选择签约公司')"
                allowClear
              >
                <a-select-option
                  v-for="item in PayCompanys"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>

            </a-form-item>
          </a-col>
          <a-col :span="12">

          </a-col>
        </a-row>
        <div>
          <a-button :style="{ marginRight: '8px' }" @click="refreshGoFirstPage">
            <a-icon type="reload" />
            <span>重置</span>
          </a-button>
          <a-button type="primary" @click="searchData">
            <a-icon type="search" />
            <span>搜索</span>
          </a-button>
        </div>
      </a-form>
    </a-drawer>
    <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :cancelText="inputModalParams.cancelText"
      :okText="inputModalParams.okText"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    >
      <div>
        <a-form :form="form" v-bind="formItemLayout">
          <a-form-item label="通过审核" required>
            <a-switch
              :disabled="inputModalParams.disabled"
              v-decorator="[
                'isPass',
                { valuePropName: 'checked', initialValue: false },
              ]"
              @change="handleChange"
            />
          </a-form-item>
          <a-form-item label="审核备注">
            <a-input
              :disabled="inputModalParams.disabled"
              type="textarea"
              v-decorator="[
                'auditRemark',
                {
                  rules: [
                    {
                      message: '请输入',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item label="不通过原因">
            <a-input
              :disabled="
                inputModalParams.disabled || inputModalParams.otherdisabled
              "
              type="textarea"
              v-decorator="[
                'notPassReason',
                {
                  rules: [
                    {
                      required: !inputModalParams.otherdisabled,
                      message: '请输入',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item
            :label="inputModalParams.disabled ? '现场照片（必填）' : '现场照片'"
            v-if="inputModalParams.uploadShow"
          >
            <a-upload
              :action="uploadUrl"
              :multiple="false"
              :data="{ subdir: this.subdirName }"
              list-type="picture"
              :file-list="fileList"
              :showUploadList="{
                showPreviewIcon: true,
                showRemoveIcon: !inputModalParams.disabled,
              }"
              @change="handleUploadChange"
            >
              <a-button v-if="!isDisabled">
                <a-icon type="upload" /> 上传附件
              </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import axios from "axios";
import { fileDownloadService } from "@/shared/utils";
import CreateOrUpdate from "./addoredit.vue";
import { message } from "ant-design-vue";
import ImportForm from "./importform.vue";
import BatchNotificationList from "./batchnotification/batchnotification_list.vue";
import moment from "moment";
import { CommonServiceProxy } from "@/shared/common-service";
import EleTable from "@/components/ele-table";
import PersonnelAgreementList from "./personnelagreement/agreementlist";
import SelectPageList from "@/components/linggong/select-page-list";
import { AppConsts } from "@/abpPro/AppConsts";
import "moment/locale/zh-cn";
import { environment } from "@/environments/environment";
import ReactModify from "@/components/react-modify";
import BusMainInfo from './busmaininfo.vue';
export default {
  mixins: [AppComponentBase, ModalComponentBase],
  name: "projectmodule",
  components: { EleTable },
  data: function () {
    return {
      // moment,
      spinning: false,
      CommericalInfo: {
        notApply: 0,
        sendApply: 0,
        applying: 0,
        success: 0,
        failed: 0,
        terminating: 0,
        terminated: 0
      },
      SearchDto: {
        RealName: "",
        FilterText: "",
        Phone: "",
        ProjectIds: "",
        ProjectName: "",
        ProjectCode: "",
        Sorting: "",
        SignStatus: "",
        AuditStatus: undefined,
        CooperateStatus: undefined,
        IsRealNamed: undefined,
        // 日期范围
        StartDate: "",
        EndDate: "",
        SkipCount: 0,
        MaxResultCount: 50,
        isSign: undefined,
        CustomerId: "",
        CompanyId:undefined,
      },
      startToEndDate: [],
      Proxy: null,
      selectedRowKeys: [],
      selectedRows: [],
      totalItems: 0,
      pageNumber: 1,
      totalPages: 1,
      pagerange: [1, 1],
      pageSizeOptions: ["10", "20", "30", "40"],
      tableData: [],
      columns: [],
      // modal
      commonService: null,
      form: this.$form.createForm(this, { name: "coordinated" }),
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "审核",
        cancelText: "取消",
        okText: "确认",
        form: {},
        id: null,
        disabled: false,
        uploadShow: false,
        uploadRequired: false,
        cancelText: "取消",
        okText: "确认",
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.visible = false;
          this.fileList = [];
        },
      },
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      isDisabled: false,
      cooperateStatusList: [
        { label: "合作", value: 1 },
        { label: "未合作", value: 2 },
      ],
      auditStatusList: [
        { label: "待审核", value: 0 },
        { label: "审核通过", value: 1 },
        { label: "审核驳回", value: 2 },
      ],
      realNamedStatusList: [
        { label: "已实名", value: 1 },
        { label: "未实名", value: 0 },
      ],
      signStatusList: [
        { label: "未签署", value: "0" },
        { label: "单方签署", value: "1" },
        { label: "已签署", value: "2" },
        { label: "已归档", value: "3" },
        { label: "已通知", value: "4" },
      ],
      auditStatusDefaultV: null,
      Customers: [],
      CustomerId: undefined,
      searchDrawerVisible: false,
      actionsType: {},
      PayCompanys: [],
    };
  },
  methods: {
            getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          // url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          url:
            this.$apiUrl + "/api/services/app/Organization/GetSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    searchData() {
      this.searchDrawerVisible = false;
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    showSearchDrawer() {
      this.searchDrawerVisible = true;
    },
    onSearchDrawerClose() {
      this.searchDrawerVisible = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async _getProjectUserPermission() {
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/ProjectUser/GetProjectUserPermission",
          params: {},
        });
        const fields = res.map(item => item.replace(item[0], item[0].toLowerCase()));
        this.initColoums(fields);
      } catch (error) {
        console.log(error);
      }
    },
    initColoums(fields) {
      let _this = this;
      let cols = [
        {
          title: this.l("ProjectHeaderName"),
          dataIndex: "projectName",
          // sorter: true,
          // fixed: "left",
          width: 200,
          // ellipsis: true,
          // align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("项目编码"),
          dataIndex: "projectCode",
          // sorter: true,
          // fixed: "left",
          // ellipsis: true,
          // align: "center",
          width: 240,
          scopedSlots: { customRender: "projectCode" },
        },
        {
          title: this.l("NameSurname"),
          dataIndex: "realName",
          // sorter: true,
          // fixed: "left",
          width: 100,
          ellipsis: true,
          align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("签署状态"),
          dataIndex: "signStatusTag",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "signStatusTag" },
        },
        {
          title: this.l("合作状态"),
          dataIndex: "cooperateStatusName",
          width: 80,
          align: "center",
          scopedSlots: { customRender: "cooperateStatus" },
        },
        {
          title: "是否实名",
          dataIndex: "isRealNamed",
          sorter: false,
          width: 100,
          align: "center",
          type: "tag",
          scopedSlots: { customRender: "isRealNamed" },
          customRender: (text) => {
            var txt = text === true ? "已实名" : "未实名";
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: txt,
              },
              attrs: { title: txt },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
        },
        {
          title: "来源",
          dataIndex: "source",
          sorter: false,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "source" },
          customRender: (text) => {
            var txt = text === "NotSetting" ? "未知来源" : text;
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: txt,
              },
              attrs: { title: txt },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
        },
        {
          title: this.l("新经济个体状态"),
          dataIndex: "commercialRegistStatus",
          sorter: true,
          align: "center",
          type: "custom",
          width: 150,
          scopedSlots: { customRender: "commercialRegistStatus" },
          customRender: function (text, record, index) {
            if (text == "None") {
              return "未申请";
            } else if (text == "Applying") {
              return "已提交申请";
            } else if (text == "Registed") {
              return "待市监局审批";
            } else if(text == "Failed") {
              return "认证失败";
            } else if (text == "Terminating") {
              return "注销中";
            } else if (text == "Terminated") {
              return "已注销";
            }
            return "已下照";
          },
        },
        {
          title: this.l("手机号"),
          dataIndex: "phone",
          sorter: false,
          width: 130,
          align: "center",
          scopedSlots: { customRender: "phone" },
        },
        {
          title: this.l("ProjectUserIdCard"),
          dataIndex: "icCard",
          width: 190,
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "icCard" },
        },
        {
          title: this.l("银行卡号"),
          dataIndex: "bankCardNo",
          width: 190,
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "bankCardNo" },
        },
        {
          title: this.l("银行名称"),
          dataIndex: "bankName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "bankName" },
        },
        {
          title: this.l("开户地"),
          dataIndex: "bankArea",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "bankArea" },
        },
        {
          title: this.l("加入时间"),
          dataIndex: "applyDate",
          sorter: true,
          align: "center",
          customRender: function (text, record, index) {
            var txt = text ? text.split("T")[0] : "";
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: txt,
              },
              attrs: { title: txt },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
          scopedSlots: { customRender: "applyDate" },
        },
        {
          title: this.l("StartDateTime"),
          dataIndex: "beginDate",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            var txt = text ? text.split("T")[0] : "";
            let child = _this.$createElement("a-tooltip", {
              domProps: {
                innerHTML: txt,
              },
              attrs: { title: txt },
            });
            let obj = {
              children: child,
            };
            return obj;
          },
          scopedSlots: { customRender: "beginDate" },
        },
        {
          title: this.l("EndDateTime"),
          dataIndex: "endDate",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "endDate" },
        },
        {
          title: this.l("审核状态"),
          dataIndex: "auditStatus",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "auditStatus" },
        },
        {
          title: this.l("备注"),
          dataIndex: "remark",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "remark" },
        },
        {
          title: this.l("审核备注"),
          dataIndex: "auditRemark",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "auditRemark" },
        },
        {
          title: this.l("不通过原因"),
          dataIndex: "reason",
          // sorter: true,
          align: "center",
          scopedSlots: { customRender: "reason" },
        },
        {
            title: this.l("新经济个体申请时间"),
            dataIndex: "commercialApplyTime",
            sorter: true,
            align: "center",
            type: "custom",
            scopedSlots: { customRender: "commercialApplyTime" },
            customRender: function (text, record, index) {
              if (text && text != null) {
                return text.replace("T", " ").substring(0, 19);
              }
              return "";
            },
        },
        {
            title: this.l("新经济个体完成时间"),
            dataIndex: "commercialRegistTime",
            sorter: true,
            align: "center",
            type: "custom",
            scopedSlots: { customRender: "commercialRegistTime" },
            customRender: function (text, record, index) {
              if (text && text != null) {
                return text.replace("T", " ").substring(0, 19);
              }
              return "";
            },
        },
        {
          title:this.l("工作城市"),
          dataIndex: "workCity",
          align:'center',

           scopedSlots: { customRender: "workCity" },
        },
        //  {
        //     title: this.l("员工进入系统时间"),
        //     dataIndex: "userCreationTime",
        //     sorter: true,
        //     align: "center",
        //     type: "custom",
        //     width: 150,
        //     scopedSlots: { customRender: "userCreationTime" },
        //     customRender: function (text, record, index) {
        //       if (text && text != null) {
        //         return text.replace("T", " ").substring(0, 19);
        //       }
        //       return "";
        //     },
        // },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          fixed: "right",
          width: 200,
          scopedSlots: { customRender: "actions" },
        },
      ];
      if (fields.indexOf('source') === -1) {
        this.columns = cols.filter(v => v.dataIndex !== 'source')
      } else {
        this.columns = cols
      }
      // this.columns = cols;
      // let newArray = [];
      // this.$api.request({
      //   url: this.$apiUrl + '/api/services/app/ProjectUser/GetProjectUserPermission',
      //   method: 'get'
      // }).then(res => {
      //   console.log('res:', res);
      //   if(res.data && res.data.length > 0) {
      //     res.data.forEach(item => {
      //       const key = item.replace(item[0], item[0].toLowerCase());
      //       console.log(key);
      //       let arr = cols.filter(it => it.dataIndex == key)
      //       if(arr && arr.length > 0) {
      //         newArray.push(arr[0]);
      //       }
      //     });
      //     let action = cols.filter(it => it.dataIndex == "actions");
      //     if(action && action.length > 0) {
      //       newArray.push(action[0]);
      //     }

      //     this.columns = newArray;
      //     console.log('newArray:', newArray);
      //   }
      // });
    },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "project-user",
        isShow: true,
        fns: {
          detailList: [
            {
              granted: this.isGranted("Pages.LGModul.ProjectPersonel.Apply"),
              name: this.l("审核"),
              icon: "profile",
              fn: (data) => {
                _this._audit(data);
              },
            },
          ],
          isShow: true,
          dropList: [
            {
              delete: true,
              tipsDesc: "确定要切换合作状态吗？",
              granted: this.isGranted(
                "Pages.LGModul.ProjectPersonel.CooperateStatusChange"
              ),
              name: this.l("切换合作状态"),
              icon: "switcher",
              fn: (data) => {
                _this.cooperateStatusChange(data);
              },
            },
            {
              granted: this.isGranted(
                "Pages.LGModule.ProjectPersonel.NotifyList"
              ),
              name: this.l("签署列表"),
              icon: "ant-design",
              fn: (data) => {
                _this._projectPersonnelAgreementModal(data);
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.ProjectPersonel.Edit"),
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data.id);
              },
            },
            {
              delete: true,
              granted: this.isGranted("Pages.LGModul.ProjectPersonel.Delete"),
              name: this.l("Delete"),
              fn: (data) => {
                _this.deleteItem(data.id);
              },
            },
            { 
              granted: this.isGranted("Pages.LGModul.ProjectPersonel.CommericalInfo"), name: this.l("新经济个体信息"), icon: "idcard", 
              fn: (data) => {
                _this.showMainInfo(data);
              }
            },
          ],
          // delete: {
          //   granted: this.isGranted("Pages.LGModul.ProjectPersonel.Delete"),
          //   name: this.l("Delete"),
          //   fn: (data) => {
          //     _this.deleteItem(data.id);
          //   },
          // },
        },
      };
      this.actionsType = obj;
    },
    showMainInfo(item) {
      ModalHelper.create(
        BusMainInfo,
        {
          id: item.userId
        },
        {
          isChange: true,
          width: "850px",
        }
      ).subscribe((res) => {
      });
    } ,
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
              searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
                    let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }

          this.SearchDto.ProjectIds = projectIds;
          this.SearchDto.ProjectName = projectNames;
          // this.SearchDto.ProjectId = data.id;
          // this.SearchDto.ProjectName = data.name;
          this.getData();
          // this.getTaskByProjectId(0, data.id);
        }
      });
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.onChange(page, pageSize);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    handleChange(value) {
      this.inputModalParams.otherdisabled = value ? true : false;
    },
    selectCooperateStatusChange(value) {
      this.SearchDto.CooperateStatus = value;
    },
    selectChange(value) {
      this.SearchDto.AuditStatus = value;
    },
    selectRealNamedChange(value) {
      this.SearchDto.IsRealNamed = value;
    },
    selectSignStatusChange(value) {
      this.SearchDto.SignStatus = value;
    },
    // 上传组件 @change
    handleUploadChange(info) {
      // console.log(info);
      if (info.file.status !== "removed") {
        let isUpload = this.beforeUpload(info.file);
        if (!isUpload) {
          return false;
        }
      }
      let fileList = [...info.fileList];
      if (fileList.length > 1) {
        abp.message.warn(`只能上传一张照片!`);
        return false;
      }
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        return file;
      });
      this.fileList = fileList;
      // console.log(this.fileList);
    },
    // 上传前校验
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      if (!isJpgOrPng) {
        abp.message.warn(`只能上传jpg/png格式的照片!`);
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        abp.message.warn(`图片不得大于5MB!`);
      }
      return isJpgOrPng && isLt2M;
    },
    _audit(params) {
      // console.log(params)
      let _this = this;
      this.inputModalParams.title = "审核";
      this.inputModalParams.visible = true;
      if (params.isNeedPresentPhoto) {
        this.inputModalParams.uploadShow = true;
      } else {
        this.inputModalParams.uploadShow = false;
      }
      if (params.auditStatus === "审核通过") {
        this.inputModalParams.disabled = true;
      } else {
        this.inputModalParams.disabled = false;
      }
      setTimeout(() => {
        this.form.setFieldsValue({
          id: params.id,
          notPassReason: params.reason,
          isPass: params.auditStatus == "审核通过" ? true : false,
          auditRemark: params.auditRemark,
        });
        this.fileList = [];
        let presentPhotoUrl = params.presentPhotoUrl;
        if (presentPhotoUrl && presentPhotoUrl.indexOf(",") !== -1) {
          let arr = presentPhotoUrl.split(",");
          arr.map((item, i) => {
            let n = item.split("/");
            this.fileList.push({
              uid: `-${i + 1}`,
              url: `${AppConsts.uploadBaseUrl}${item}`,
              name: n[n.length - 1],
              response: { data: { Path: item } },
              status: "done",
            });
          });
        } else if (presentPhotoUrl && presentPhotoUrl !== "undefined") {
          let n = presentPhotoUrl.split("/");
          this.fileList.push({
            uid: `-1`,
            url: `${AppConsts.uploadBaseUrl}${presentPhotoUrl}`,
            name: n[n.length - 1],
            response: { data: { Path: presentPhotoUrl } },
            status: "done",
          });
        }
      }, 300);

      this.inputModalParams.confirm = function () {
        _this.form.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            let fileUrlList = [];
            _this.fileList.map((item) => {
              fileUrlList.push(item.response.data.Path);
            });
            const images = fileUrlList.length
              ? fileUrlList.length > 1
                ? fileUrlList.join(",")
                : `${fileUrlList[0]}`
              : "";
            if (
              _this.inputModalParams.disabled &&
              params.isNeedPresentPhoto &&
              !images
            ) {
              abp.message.warn(`审核通过情况下必须上传照片!`);
              _this.inputModalParams.confirmLoading = false;
              return;
            }
            try {
              let _res = await _this.commonService.post({
                url: "/api/services/app/ProjectUser/Audit",
                params: {
                  notPassReason: values.notPassReason,
                  isPass: values.isPass,
                  auditRemark: values.auditRemark,
                  id: params.id,
                  presentPhotoUrl: images,
                },
              });
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
              _this.getData();
              _this.$notification["success"]({
                message: _this.l("操作成功"),
              });
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },

    // 合作状态变更
    async cooperateStatusChange(item) {
      console.log(item);
      let status = item.cooperateStatus === 1 ? 2 : 1;
      try {
        const res = await this.commonService.post({
          url: "/api/services/app/ProjectUser/CooperateStatusChange",
          params: {
            id: item.id,
            cooperateStatus: status,
          },
        });
        message.success("切换成功");
        this.spinning = false;
        this.getData();
      } catch (error) {
        this.spinning = false;
      }
    },
    _projectPersonnelAgreementModal(item) {
      ModalHelper.create(
        PersonnelAgreementList,
        { personnelAgreementItem: item },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    _auditRange() {
      if (!this.selectedRows.length) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      const _arr = this.selectedRows.filter(
        (v) => v.isNeedPresentPhoto || v.auditStatus === "审核通过"
      );
      if (_arr.length) {
        abp.message.warn(
          `您选择的名单中有状态为"审核通过"或需要上传现场照片的人员，无法批量处理`
        );
        return;
      }
      let _this = this;
      this.inputModalParams.show = false;
      this.inputModalParams.title = "批量审核";
      this.inputModalParams.confirm = function () {
        _this.form.validateFields(async (err, values) => {
          if (!err) {
            console.log("Received values of form: ", values);
            _this.inputModalParams.confirmLoading = true;
            try {
              let _res = await _this.commonService.post({
                url: "/api/services/app/ProjectUser/AuditRange",
                params: {
                  notPassReason: values.notPassReason,
                  isPass: values.isPass,
                  ids: _this.selectedRows.map((v) => {
                    return v.id;
                  }),
                },
              });
              _this.inputModalParams.confirmLoading = false;
              _this.inputModalParams.visible = false;
              _this.selectedRowKeys = [];
              _this.selectedRows = [];
              _this.refreshGoFirstPage();
              _this.$notification["success"]({
                message: this.l("操作成功"),
              });
            } catch (error) {
              _this.inputModalParams.confirmLoading = false;
            }
          }
        });
      };
      this.inputModalParams.visible = true;
    },
    search() {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    getData() {
      this.spinning = true;
      // console.log(this.startToEndDate);
      let url = this.$apiUrl + "/api/services/app/ProjectUser/GetPageList";
      this.SearchDto.StartDate =
        this.startToEndDate[0] === null ? undefined : this.startToEndDate[0];
      this.SearchDto.EndDate =
        this.startToEndDate[1] === null ? undefined : this.startToEndDate[1];
      this.SearchDto.CustomerId = this.CustomerId;
      let data = {};
      this.SearchDto.isSigned = this.SearchDto.isSign;
      // this.SearchDto.projectIds = this.SearchDto.projectId;
      Object.assign(data, this.SearchDto);
      // data.isSigned =
      //   !data.isSign || data.isSign == ""
      //     ? null
      //     : data.isSign == "0"
      //     ? false
      //     : true;
      // delete data.isSign;
      let options = {
        data: data,
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      };
      this.$api
        .request(options)
        .then((response) => {
          let res = response.data;
          this.tableData = res.items.map((item) => {
            return {
              ...item,
              auditStatus:
                item.auditStatus === 0
                  ? "待审核"
                  : item.auditStatus === 1
                  ? "审核通过"
                  : "审核驳回",
              isRealNamed: item.isRealNamed ? "已实名" : "未实名",
              isRealNamedColor: item.isRealNamed ? "#87d068" : "#f50",
              applyDate: moment(item.applyDate).format("YYYY-MM-DD"),
              beginDate: moment(item.beginDate).format("YYYY-MM-DD"),
              endDate: moment(item.endDate).format("YYYY-MM-DD"),
            };
          });
          this.totalItems = res.totalCount;
          this.totalPages = Math.ceil(
            res.totalCount / this.SearchDto.MaxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
            this.pageNumber * this.SearchDto.MaxResultCount,
          ];
          this.CommericalInfo = res.statisticalInfo;
        })
        .catch((e) => {
          console.error(e);
          abp.message.warn(this.l("DefaultErrorMessage"));
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    refreshGoFirstPage() {
      this.searchDrawerVisible = false;
      this.SearchDto.ProjectIds = "";
      this.SearchDto.ProjectName = "";
      this.SearchDto.ProjectCode = "";
      this.SearchDto.RealName = "";
      this.SearchDto.FilterText = "";
      this.SearchDto.Phone = "";
      this.SearchDto.SignStatus = "";
      this.SearchDto.AuditStatus = undefined;
      this.SearchDto.CooperateStatus = undefined;
      this.SearchDto.Sorting = "";
      this.SearchDto.StartDate = "";
      this.SearchDto.EndDate = "";
      this.startToEndDate = [];
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.CustomerId = undefined;
      this.SearchDto.IsRealNamed = undefined;
      this.SearchDto.isSign = undefined;
      this.SearchDto.CompanyId = undefined;
      this.getData();
    },
    createOrEdit(id) {
      ModalHelper.create(
        CreateOrUpdate,
        { id: id },
        {
          width: "640px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    batchDelete() {
      const selectCount = this.selectedRows.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      this.message.confirm(
        this.l("ConfirmDeleteXItemsWarningMessage", selectCount),
        (res) => {
          if (res) {
            const ids = _.map(this.selectedRows);
            this.spinning = true;
            let url =
              this.$apiUrl + "/api/services/app/ProjectUser/BatchDelete";
            let options = {
              data: ids,
              method: "POST",
              url: url,
              headers: {
                "Content-Type": "application/json-patch+json",
              },
            };
            this.$api
              .request(options)
              .finally(() => {
                this.spinning = false;
              })
              .then(() => {
                this.selectedRowKeys = [];
                this.selectedRows = [];
                this.refreshGoFirstPage();
                this.$notification["success"]({
                  message: this.l("SuccessfullyDeleted"),
                });
              });
          }
        }
      );
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * this.SearchDto.MaxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    deleteItem(id) {
      this.spinning = true;
      let url = this.$apiUrl + "/api/services/app/ProjectUser/BatchDelete";
      let options = {
        data: [id],
        method: "POST",
        url: url,
        headers: {
          "Content-Type": "application/json-patch+json",
        },
      };
      this.$api
        .request(options)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    p(s) {
      return s < 10 ? "0" + s : s;
    },
    changeTime(val) {
      if (val) {
        let d = new Date(val.toString());
        let startDate =
          d.getFullYear() +
          "-" +
          this.p(d.getMonth() + 1) +
          "-" +
          this.p(d.getDate());
        return startDate;
      }
      return undefined;
    },
    restCheckStatus() {
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    importData() {
      ModalHelper.create(
        ImportForm,
        { id: "" },
        {
          width: "860px",
        }
      ).subscribe((res) => {
        this.refreshGoFirstPage();
      });
    },
    async exportCommericalExcelFile() {
      this.spinning = true;
      this.SearchDto.StartDate =
        this.startToEndDate[0] === undefined
          ? undefined
          : this.changeTime(this.startToEndDate[0]); //this.startToEndDate[0].toString().split("T")[0] ;
      this.SearchDto.EndDate =
        this.startToEndDate[1] === undefined
          ? undefined
          : this.changeTime(this.startToEndDate[1]); //this.startToEndDate[1].toString().split("T")[0];
                this.SearchDto.isSigned = this.SearchDto.isSign;
                      // this.SearchDto.projectIds = this.SearchDto.projectId;
      const opts = {
        ...this.SearchDto,
        StartDate: this.SearchDto.StartDate,
        EndDate: this.SearchDto.EndDate,
        MaxResultCount: this.totalItems,
        SkipCount: 0,
        IsRealNamed:
          this.SearchDto.IsRealNamed === 1
            ? true
            : this.SearchDto.IsRealNamed === 0
            ? false
            : "",
      };

      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectUser/ExportCommericalInfo",
          params: opts,
        });
        this.spinning = false;
        fileDownloadService.downloadTempFile(res);
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getPageListExcelFile() {
      this.spinning = true;

      this.SearchDto.StartDate =
        this.startToEndDate[0] === undefined
          ? undefined
          : this.changeTime(this.startToEndDate[0]); //this.startToEndDate[0].toString().split("T")[0] ;
      this.SearchDto.EndDate =
        this.startToEndDate[1] === undefined
          ? undefined
          : this.changeTime(this.startToEndDate[1]); //this.startToEndDate[1].toString().split("T")[0];
                this.SearchDto.isSigned = this.SearchDto.isSign;
                      // this.SearchDto.projectIds = this.SearchDto.projectId;
      const opts = {
        ...this.SearchDto,
        StartDate: this.SearchDto.StartDate,
        EndDate: this.SearchDto.EndDate,
        MaxResultCount: this.totalItems,
        SkipCount: 0,
        IsRealNamed:
          this.SearchDto.IsRealNamed === 1
            ? true
            : this.SearchDto.IsRealNamed === 0
            ? false
            : "",
      };

      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectUser/GetPageListExcelFile",
          params: opts,
        });
        this.spinning = false;
        if(res.fileName.startsWith("taskexport")) {
          abp.message.success("导出的数据量过大，已创建导出任务，可以稍后通过任务代码【" + res.fileName.replace("taskexport-", "") + "】查询导出状态并下载文件");
          return;
        }
        fileDownloadService.downloadTempFile(res);
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    batchNotification() {
      const selectCount = this.selectedRows.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      let projectIds = this.selectedRows.map((item) => {
        return item.projectId;
      });
      let projectArr = projectIds.filter(function (item, index, projectIds) {
        return projectIds.indexOf(item, 0) === index;
      });
      if (projectArr != null && projectArr.length != 1) {
        abp.message.warn(this.l("同一个项目下的用户才可以进行批量签署"));
        return false;
      }
      let userIds = this.selectedRows.map((item) => {
        return item.userId;
      });
      ModalHelper.create(
        BatchNotificationList,
        { selectUserIds: userIds, projectId: projectArr[0] },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
                // console.log(this.Customers)
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  created() {
    if (this.$route.query && this.$route.query.auditStatus) {
      this.SearchDto.AuditStatus = Number(this.$route.query.auditStatus);
    }
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this._getProjectUserPermission()
    // this.initColoums();
    // this.getData();
    // this.getAllCutomer();
    // this.initActionsType();
    this.getAllPayCompany();
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  mounted() {
    this.getData();
    this.getAllCutomer();
    this.initActionsType();
  },
  changTime(time) {
    const d = new Date(time);
    const resDate =
      d.getFullYear() +
      "-" +
      this.p(d.getMonth() + 1) +
      "-" +
      this.p(d.getDate());
    const resTime =
      this.p(d.getHours()) +
      ":" +
      this.p(d.getMinutes()) +
      ":" +
      this.p(d.getSeconds());
    return resTime;
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}
.btn--footer {
  margin-bottom: 20px;
}
</style>
