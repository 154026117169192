<template>
    <a-spin :spinning="spinning">
        <a-card :bordered="false" style="height:700px;max-height:700px;overflow-y:auto;">
            <a-row :gutter="24" type="flex" style="margin-bottom:8px;">
                <a-col class="gutter-row" :span="6">
                    <a-input v-model="SearchDto.Name"
                             :placeholder="l('ProjectHeaderName')" />
                </a-col>
                <a-col class="gutter-row" :span="6">
                    <a-input v-model="SearchDto.Code"
                             :placeholder="l('ProjectHeaderCode')" />
                </a-col>
                <a-col class="gutter-row" :span="6">
                    <a-input v-model="SearchDto.ClientName"
                             :placeholder="l('ProjectHeaderCustomerName')" />
                </a-col>
                <a-col class="gutter-row" :span="6">
                    <a-input v-model="SearchDto.CompanyName"
                             :placeholder="l('ProjectHeaderCompanyName')" />
                </a-col>
                <a-col class="gutter-row" :span="6" align="center">
                    <a-button type="primary" @click="search">
                        {{ l("Search") }}
                    </a-button>
                    <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                </a-col>
            </a-row>

            <a-table :data-source="tableData" :pagination="pageData" @change="tableChange"
                     :row-selection="{ selectedRowKeys: selectedRowKeys,onChange: selectChange }">
                <a-table-column-group>
                    <a-table-column :key="1" align="center" :title="l('ProjectHeaderName')" data-index="projectName" />
                    <a-table-column :key="2" align="center" :title="l('ProjectHeaderCode')" data-index="projectCode" />
                    <a-table-column :key="3" align="center" :title="l('ProjectType')" data-index="projectTypeName" />
                    <a-table-column :key="4" align="center" :title="l('StartDateTime')" data-index="beginDate">
                        <template slot-scope="text, scope">
                            {{text ? text.substring(0,10) : ''}}
                        </template>
                    </a-table-column>
                    <a-table-column :key="5" align="center" :title="l('EndDateTime')" data-index="endDate">
                        <template slot-scope="text, scope">
                            {{text ? text.substring(0,10) : ''}}
                        </template>
                    </a-table-column>
                    <a-table-column :key="6" align="center" :title="l('ProjectHeaderCustomerName')" data-index="clientName" />
                    <a-table-column :key="7" align="center" :title="l('ProjectHeaderCompanyName')" data-index="companyName" />
                    <a-table-column :key="8" align="center" :title="l('ProjectStatus')" data-index="projectStatusName"></a-table-column>
                </a-table-column-group>
            </a-table>
        </a-card>
        <a-row :gutter="10">
            <a-col :span="5" :offset="19">
                <a-button type="primary" @click="SureSelected">{{l("Sure")}}</a-button>&nbsp;
                <a-button @click="CloseModel">{{l("Close")}}</a-button>
            </a-col>
        </a-row>
    </a-spin>
</template>
<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import axios from "axios";
    import moment from "moment";

    export default {
        mixins: [ModalComponentBase],
        name: "projectmodule",
        data: function () {
            return {
                spinning: false,
                SearchDto: {
                    Name: "",
                    Code: "",
                    ClientName: "",
                    CompanyName:"",
                    SkipCount: 0,
                    MaxCount: 8
                },
                selectedRowKeys: [],
                selectedRows: [],
                tableData: [],
                pageData: { current: 1, pageSize: 8, total: 0 },
            };
        },
        methods: {
            search() {
                this.pageData.current = 1
                this.SearchDto.SkipCount = 0
                this.getData()
            },
            getData() {
                this.spinning = true;
                let url = this.$apiUrl + '/api/services/app/Project/GetProjectPaged';
                let options = {
                    params: {
                        projectName: this.SearchDto.Name,
                        projectCode: this.SearchDto.Code,
                        clientName: this.SearchDto.ClientName,
                        companyName: this.SearchDto.CompanyName,
                        maxResultCount: this.SearchDto.MaxCount,
                        skipCount: this.SearchDto.SkipCount
                    },
                    method: "GET",
                    url: url,
                    headers: {
                        "Content-Type": "application/json-patch+json",
                    }
                };
                this.$api.request(options).then(response => {
                    let res = response.data;
                    this.tableData = res.items;
                    this.pageData.total = res.totalItems
                }).catch(e => {
                    console.error(e);
                }).finally(() => {
                    this.spinning = false;
                });
            },
            tableChange(pagination, filters, sorter) {
                console.info(pagination);
                const pager = { ...this.pageData };
                pager.current = pagination.current;
                this.SearchDto.SkipCount = (pager.current - 1) * this.SearchDto.MaxCount;
                this.pageData = pager;
                this.getData();
            },
            selectChange(keys) {
                this.selectedRows.splice(0, this.selectedRows.length);
                this.selectedRowKeys = keys;
                for (let i = 0; i < keys.length; i++) {
                    var index = keys[i];
                    this.selectedRows.push(this.tableData[index]);
                }
            },
            SureSelected() {
                if (!this.selectedRows || this.selectedRows.length <= 0) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return false;
                }
                this.success({ success: true, data: this.selectedRows[0] });
            },
            CloseModel() {
                this.success({ success: false });
            },
            refreshGoFirstPage() {
                this.SearchDto.Name = "";
                this.SearchDto.Code = "";
                this.SearchDto.ClientName = "";
                this.SearchDto.CompanyName = "";
                this.SearchDto.SkipCount = 0;
                this.pageData.current = 1;
                this.getData();
            }
        },
        created() {
            this.fullData();
        },
        mounted() {
            this.getData();
        }
    };
</script>
<style lang="less" scoped>
    .btn--container {
        margin-top: 20px;
    }

    .btn--footer {
        margin-bottom: 20px;
    }

    .tbl-edit {
        display: block;
        margin-top: 3px;
    }
</style>
